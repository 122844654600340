import http from '@/http-common'
import {ApiService} from '@/services/ApiService'

const URL = '/api/manual-worldcheck-case'

class WorldcheckManualService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    download(id){
        return http.get(`${this.getUrl()}/download/${id}`, {'responseType': 'arraybuffer'})
    }
    update(data) {
        return http.put(`/api/worldcheck-case/${data.id}`, data)
    }
}

export default new WorldcheckManualService()