<template>
    <Overview 
        :moduleName="$tc('navigation.world_check_manual', 1)"
        :moduleNamePlural="$tc('navigation.world_check_manual', 2)"
        :sort="'updated_at'"
        :sortOrder="'desc'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :checkIfCanShowDelete="checkIfCanShowDelete"
        :showAddButton="false"
        :searchBefore="false"
        :extraOptions=extraOptions
        ref="overview"
    >

        <template #extrasearchoptions>
            <img id="wclogo-5" width="250" src="https://nmtmainport.com.netvibestest.net/img/world-check-logo.png" />
            <div class="w-50">
                <div class="fw-bold col-form-label-sm">{{ $t('sales.new_x', [$t('navigation.world_check')]) }}</div>
                <FormItem id="rtype" type="radiogroup" :label="$t('overview.type')" v-model="newcase.type" :options="types" />
                <FormItem id="squery" :label="$t('crm.wc_query')" v-model="newcase.search_query" />
                <FormItem id="ppno" v-if="newcase.type == 'individual'" :label="$t('crm.wc_passport')" v-model="newcase.passport_number" />
                <div class="savebutton w-50">
                    <FormItem type="download" id="savebutton" :label="$t('crm.wc_submit')" :service="save" :options="{small: true, send: true}" startTag="fa fa-save" :disabled="isInvalid" />
                </div>
                <div class="fw-bold col-form-label-sm mt-2">{{ $t('overview.my_x', [$tc('navigation.world_check', 2)]) }}</div>
            </div>
        </template>
        <template #columns>
            <Column field="updated_at" :header="$t('overview.date')" style="width: 9rem">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.updated_at, 'shortDate')}}
                </template>
            </Column>
            <Column field="type" :header="$t('overview.type')" style="width: 9rem">
                <template #body="slotProps">
                    {{typesObj[slotProps.data.type]}}
                </template>
            </Column>
            <Column field="search_query" header="Query"></Column>
            <Column field="external_case_id" header="Hits" style="width: 6rem" :sortable="false">
                <template #body="slotProps">
                    <span v-if="slotProps.data.worldcheck_case && slotProps.data.worldcheck_case.worldcheck_case_lines.length">
                        {{ slotProps.data.worldcheck_case.worldcheck_case_lines.length }}
                    </span>
                    <span v-else>-</span>
                </template>
            </Column>
            <Column field="worldcheck_case.status" :header="$t('overview.status')" style="width: 9rem" :sortable="false">
                <template #body="slotProps">
                    <template v-if="slotProps.data.worldcheck_case && slotProps.data.worldcheck_case.status != 'pending'">
                        <span class="badge" :data-status="slotProps.data.worldcheck_case.status">
                            {{ getStatus(slotProps.data.worldcheck_case) }}
                        </span>
                    </template>
                    <template v-else-if="slotProps.data.worldcheck_case">
                        <span class="badge" data-status="declined">
                            {{ $t('quote_status.action_req') }}
                        </span>
                    </template>
                    <span v-else class="badge" data-status="pending">
                        {{ $t('quote_status.queued') }}
                    </span>
                </template>
            </Column>
            <Column field="user.name" :header="$t('form.created_by')"></Column>
        </template>
        <template #form>
            <WorldcheckForm ref="ItemForm" :itemData="item" />
        </template>
        <template #modal-footer>
            <FormItem type="download" id="downloadbutton" :label="$t('form.download')" startTag="fal fa-file-pdf" :service="download" />
        </template>
    </Overview>

    <dialog id="details-dialog">
        <form method="dialog" class="w-100 text-end pe-5">
            <button class="btn" style="position: fixed; width: 2rem" @click="loading = false"><i class="fa fa-close"></i></button>
        </form>
        <pre class=""></pre>
    </dialog>

</template>

<script>
    import fileService from "@/services/FileService";
    import worldcheckService from '@/services/WorldcheckManualService';
    import worldcheckParentService from '@/services/WorldcheckService';
    import Overview from '@/components/Overview';
    import Column from '@/components/column';
    import WorldcheckForm from '@/components/relation/WorldcheckForm';
    import store from '@/store/user';

    export default {
        components: {
            Overview,
            Column,
            WorldcheckForm
        },
        data() {
            return {
                types: [],
                typesObj: {},
                item: {},
                newcase: {type: 0, search_query: '', passport_number: ''},
                loading: true,
                saving: false,
                itemService: worldcheckService,
                extraOptions: [],
                itemFormRef: null,
                selectFields: [
                    'id',
                    'external_case_id',
                    'status',
                    'type',
                    'relation_id',
                    'updated_at',
                    'file_name',
                    {
                        "relation": [
                            'id',
                            'name'
                        ]
                    },
                    {
                        "worldcheckCaseLines": [
                            "id",
                            "selected",
                            "primary_name",
                            "worldcheck_case_id",
                            "categories"
                        ]
                    }
                ]
            }
        },
        computed: {
            isInvalid(){
                return !(this.newcase.search_query && this.newcase.type);
            }
        },
        methods: {
            save() {
                if(this.isInvalid){
                    return false;
                }
                return worldcheckService.store(this.newcase).then((response) => {
                    this.$toast.success(this.$t('form.created_x', [this.$tc('navigation.world_check', 2)]));
                    this.newcase = {type: 0, search_query: '', passport_number: ''};
                    this.$refs.overview.getIndex();
                }).catch(error => {
                    this.toastError(error);
                });
            },

            download(){
                const item = this.$refs.ItemForm.worldcheck;
                return worldcheckParentService.download(item.id).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    let name = 'worldcheck-' + item.external_case_id + '-' + item.relation.name.toLowerCase().replaceAll(' ', '') + ".pdf";
                    if (item.file_name) {
                        name = item.file_name
                    }
                    link.href = url;
                    link.setAttribute("download", `${name}`);
                    document.body.appendChild(link);
                    link.click();
                }).catch(error => {
                    this.toastError(error)
                });
            },

            getSelectedCaseLine(worldcheck) {
                const result = worldcheck.worldcheck_case_lines.find(el => el.selected === true);
                return result ? (result.primary_name + ' - ' + result.categories) : '-';
            },
            filterOnType(e){
                this.$refs.overview.setExtraParams(e.target.value == this.$t('form.all') ? false : e.target.value);
            },

            getStatus(data){
                if(data.status == 'pending'){
                    return this.$t('quote_status.' + data.status);
                }
                return this.$t('quote_status.' + data.status) + ', ' + (data.worldcheck_case_lines.length ? 'manual' : 'auto')
            },

            checkIfCanShowDelete(data){
                return false;
            }
        },
        mounted() {
            this.loading = true;
            this.types = [{id: 'company', name: this.$t('crm.company_type')}, {id: 'individual', name: this.$t('crm.person')}, {id: 'vessel', name: this.$t('navigation.vessels')}];
            this.typesObj = {'company': this.$t('crm.company_type'), 'individual': this.$t('crm.person_short'), 'vessel': this.$t('navigation.vessels')};
            if(!store.getters.getCompanyHq){
                window.location.href = '/';
                return;
            }
            this.itemFormRef = this.$refs.ItemForm;
            this.extraOptions = [{
                icon: 'download',
                name: this.$t('form.download', ["PDF"]),
                show: (slotProps) => {return slotProps.data.worldcheck_case != null},
                function: (slotProps) => {
                    return worldcheckParentService.download(slotProps.data.worldcheck_case.id).then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        let name = 'worldcheck-' + slotProps.data.worldcheck_case.external_case_id + '-' + slotProps.data.search_query.toLowerCase().replaceAll(' ', '') + ".pdf";
                        if (slotProps.data.worldcheck_case.file_name) {
                            name = slotProps.data.worldcheck_case.file_name
                        }
                        link.href = url;
                        link.setAttribute("download", `${name}`);
                        document.body.appendChild(link);
                        link.click();
                    }).catch(error => {
                        this.toastError(error)
                    });
                }
            }];
        }
    }
</script>
<style scoped>
#wclogo-5{
    position: absolute;
    top: 1.5rem;
    right: 1rem;
}
.savebutton{
    position: relative;
    left: calc(33% + .75rem);
}
#details-dialog{
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    min-width: 50rem
}
</style>